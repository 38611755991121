<template>
<div class="home">
  <div class="titre">
    <h1>💊 Bienvenue chez Spiruliars 💊</h1>
    <small>Le <strong>n°1</strong> de la vente de spiruline </small>
  </div>
  <h2>Kézako ?</h2>
  <p>
    La spiruline est une algue spiralée qui existe depuis 3 milliards d’années. Faible en calorie, elle contient une grande quantité de protéines, d’antioxydants (caroténoïdes, phycocyanine) et d’acide gamma-linolénique (issu de la famille des
    oméga-6). Des recherches mettent en avant des propriétés antioxydantes, antidiabétiques et immunostimulantes de la spiruline, ainsi que ses capacités à réduire le taux de lipides sanguins. Ayant des effets stimulants, les compléments alimentaires
    de spiruline ne devraient pas être consommés le soir. Si vous souffrez de phénylcétonurie, consultez obligatoirement votre médecin avant d’entreprendre une cure.</p>
  <br>
  <p>Nos producteurs locaux vous fournissent la spiruline de qualité venant de nos meilleurs champs</p>
  <br>
  <p>Utilisez notre spiruline premium afin de vous faire un magnifique shampoing pour avoir une chevelure de déesse</p><br>

  <img src="https://www.spirulinefrance.fr/wp-content/uploads/2020/09/alograpolis-lingots-spiruline.jpg" alt="tablette de spiruline" class="tablette">
  <img src="https://media4.giphy.com/media/1014INICXgJ4Os/giphy.gif?cid=ecf05e4774nk7o4s2zce7ptvn4vkig0k3w1bngadd170twnk&rid=giphy.gif&ct=g" alt="shampoo" class="shampoo">
</div>

<!--<ResellersList />
    <CustomersList />
    <SuppliersList />-->
</template>

<script>
// import CustomersList from './CustomersList.vue'
// import ResellersList from './ResellersList.vue'
// import SuppliersList from './SuppliersList.vue'

export default {
  name: 'Home',
  // props: {
  //   msg: String
  // },
  components: {
    // CustomersList,
    // ResellersList,
    // SuppliersList,
  }
}
</script>
