var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list"},[_c('h2',[_vm._v("Nos Fournisseurs")]),_c('div',[_c('router-link',{staticClass:"carte",attrs:{"to":{ name: 'map', params:{ datas: _vm.suppliers} }}},[_vm._v(" Voir sur la carte ")]),_c('router-link',{staticClass:"carte",attrs:{"to":{
              name:'AddSupplier',
              params:{
                id:_vm.suppliers.id,
                supplier:_vm.suppliers,
              }
            }}},[_vm._v(" Ajouter un Fournisseur ")])],1),_c('b-table',{attrs:{"data":_vm.suppliers}},[_c('b-table-column',{attrs:{"field":"id","label":"ID","centered":"","numeric":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.id)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"name","label":"Supplier","centered":"","numeric":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.name)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"checkedAt","label":"Date d'Insertion","centered":"","numeric":"","sortable":"","value":_vm.formatDate()},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.checkedAt)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"status","type":"boolean","label":"Stock","numeric":"","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.status === true? "Oui" : "Non")+" ")]}}])}),_c('b-table-column',{attrs:{"field":"action","label":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('router-link',{attrs:{"to":{
          name:'Supplier',
          params:{
            id:props.row.id,
            supplier:props.row,
            }
          }}},[_c('b-button',{attrs:{"type":"is-success is-light"}},[_vm._v("Voir")])],1),_c('router-link',{attrs:{"to":{
          name:'EditSupplier',
          params:{
            // id:props.row.id,
            supplier:props.row,
            }
          }}},[_c('b-button',{attrs:{"type":"is-warning is-light "}},[_vm._v("Editer")])],1),_c('b-button',{attrs:{"type":"is-danger is-light"},on:{"click":function($event){return _vm.deleteID(props.row.id)}}},[_vm._v("Supprimer")])]}}])})],1),_c('br'),_c('b-button',{attrs:{"type":"is-success is-light"},on:{"click":function($event){return _vm.pages(1)}}},[_vm._v("Previous")]),_c('b-button',{attrs:{"type":"is-success is-light"},on:{"click":function($event){return _vm.pages(2)}}},[_vm._v("Next")]),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }