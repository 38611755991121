var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list"},[_c('h2',[_vm._v("Nos commandes")]),_c('div',{staticClass:"bouton-carte"},[_c('router-link',{staticClass:"carte",attrs:{"to":{
            name:'AddOrder',
            params:{
              id:_vm.orders.id,
              order:_vm.orders,
            }
          }}},[_vm._v(" Passer une commande ")])],1),_c('div',{staticClass:"prev-next"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentPage-1>0),expression:"currentPage-1>0"}],staticClass:"previous",attrs:{"type":"is-success is-light"},on:{"click":_vm.previousPage}},[_vm._v("⬅")]),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentPage+1<=_vm.lastPage),expression:"currentPage+1<=lastPage"}],staticClass:"next",attrs:{"type":"is-success is-light"},on:{"click":_vm.nextPage}},[_vm._v("➡")])],1),(_vm.loading)?_c('p',[_vm._v("Loading...")]):_vm._e(),_c('b-table',{attrs:{"data":_vm.orders}},[_c('b-table-column',{attrs:{"field":"id","label":"ID","centered":"","numeric":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.id)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"date","label":"Date de commande","centered":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.date)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"number","label":"Numéro de commande","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.number)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"action","label":"","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('router-link',{attrs:{"to":{
            name:'Order',
            params:{
              id:props.row.id,
              order:props.row,
            }
          }}},[_c('b-button',{attrs:{"type":"is-success is-light"}},[_vm._v("Voir")])],1),_c('router-link',{attrs:{"to":{
            name:'UpdateOrder',
            params:{
              id:props.row.id,
              order:props.row,
             }
          }}},[_c('b-button',{attrs:{"type":"is-warning is-light"}},[_vm._v("Editer")])],1),_c('b-button',{attrs:{"type":"is-danger is-light"},on:{"click":function($event){return _vm.deleteID(props.row.id)}}},[_vm._v("Supprimer")])]}}])})],1),_c('br'),_c('div',[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentPage-1>0),expression:"currentPage-1>0"}],attrs:{"type":"is-success is-light"},on:{"click":_vm.previousPage}},[_vm._v("Précédent")]),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentPage+1<=_vm.lastPage),expression:"currentPage+1<=lastPage"}],attrs:{"type":"is-success is-light"},on:{"click":_vm.nextPage}},[_vm._v("Suivant")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }